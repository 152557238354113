<template>
  <div class="flex flex-col justify-center items-center">
    <HeaderGlobal
      :step="$t('global_step_01_03')"
      :title="$t('global_header_registration_method')"
      :titlemenu1="$t('cost_tool_coming_soon_section_title')"
      titlemenu2=" Feature Coming Soon "
    />

    <div class="w-11/12 mt-60 text-center font-proximaNormal">
      <h3 class="mb-6 text-md">
        We are going to launch our
        <span class="font-proximaMedium text-blue">Cost Tool</span> very soon,
        stay tuned.
        <!-- {{ $t("pet_record_overview_with_pets_title") }} -->
      </h3>
      <button class="notifyButton">
        <div class="flex items-center">
          <MailOutline class="ml-3" />
          <h3 class="ml-10 text-md">Notify Me</h3>
          <ArrowForwardIos
            class="ml-3"
            width="20px"
            height="20px"
            iconColor="black"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import ArrowForwardIos from "../icons/ArrowForwardIos.vue";
import MailOutline from "../icons/MailOutline.vue";

export default {
  components: {
    HeaderGlobal,
    ArrowForwardIos,
    MailOutline,
  },
};
</script>

<style lang="scss" scoped>
.notifyButton {
  width: 285px;
  height: 86px;
  left: 943px;
  top: 477px;
  background: #f89520;
  border-radius: 40px;
}
</style>