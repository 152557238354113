<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2934 10.0032L5.69336 4.40322L7.29336 2.80322L14.4934 10.0032L7.29336 17.2032L5.69336 15.6032L11.2934 10.0032Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#19C0CC",
    },
  },
};
</script>